import { parse } from '@engined/client/components/Interpolate.grammar.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { Resource } from '@engined/core/services/i18n.js';
import { Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Components = React.ReactNode[] | { [key: string]: React.ReactNode };

type FixMe = any;

interface OwnProps {
  className?: string;
  parent?: string;
  resource: Resource;
  components?: Components;
  disableDefaultComponents?: boolean;
  count?: number;
}

type Props = OwnProps & Record<string, FixMe>;

const defaultComponents: Components = {
  a: <Link />,
  ul: <ul />,
  li: <li />,
  ol: <ol />,
  strong: <strong />,
  em: <em />,
  pre: <pre />,
  br: <br />,
  link: <Link component={RouterLink} to="" />,
};

const Interpolate: React.FunctionComponent<Props> = ({ resource, ...rest }) => {
  const { t } = useLocale();
  const message = t(resource, { count: rest.count });
  return <SafeHTML message={message} {...rest} debugKey={resource.key} />;
};

Interpolate.displayName = 'Interpolate';

export default React.memo(Interpolate);

interface SafeHTMLOwnProps {
  className?: string;
  parent?: string;
  message: string;
  components?: Components;
  disableDefaultComponents?: boolean;
  debugKey?: string;
}

export const SafeHTML: React.FunctionComponent<SafeHTMLOwnProps & Record<string, FixMe>> = ({
  className,
  parent = 'span',
  message,
  components,
  disableDefaultComponents = false,
  debugKey,
  ...rest
}) => {
  let ast: Node[];
  try {
    ast = parse(message);
  } catch (err) {
    ast = [
      { type: 'text', text: message },
      { type: 'element', name: 'br', children: [], attributes: {} },
      {
        type: 'element',
        name: 'strong',
        attributes: {},

        children: [{ type: 'text', text: `Error: ${err.message} ` }],
      },
      { type: 'element', name: 'br', children: [], attributes: {} },
      {
        type: 'element',
        name: 'em',
        children: [
          {
            type: 'text',
            text: `&nbsp;&nbsp;at ${debugKey}${err.location ? ':' + locationToString(err.location.start) : ''}`,
          },
        ],
        attributes: {},
      },
    ];
  }

  return React.createElement(
    parent,
    { className },
    ast.map((n, i) => serialize(n, i, rest, components, disableDefaultComponents)),
  );
};

SafeHTML.displayName = 'SafeHTML';

interface Variable {
  type: 'variable';
  name: string;
}

type AttributeValue = Array<Text | Variable>;
type Attributes = { [key: string]: AttributeValue };

interface Element {
  type: 'element';
  name: string;
  attributes: Attributes;
  children: Node[];
}

interface Text {
  type: 'text';
  text: string;
}

type Node = Variable | Element | Text;

function convertEntities(str: string) {
  return str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, '\xA0')
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&');
}

function serialize(
  node: Node,
  index: number,
  variables: { [key: string]: React.ReactNode },
  components: Components,
  disableDefaultComponents: boolean,
) {
  switch (node.type) {
    case 'text':
      return <React.Fragment key={index}>{convertEntities(node.text)}</React.Fragment>;
    case 'element': {
      const comp = components?.[node.name] ?? (disableDefaultComponents ? null : defaultComponents[node.name]);
      const children = node.children.length
        ? node.children.map((n, i) => serialize(n, i, variables, components, disableDefaultComponents))
        : undefined;
      return comp ? (
        React.cloneElement(comp, {
          ...serializeAttributes(node.attributes, variables),
          children,
          key: index,
        })
      ) : (
        <React.Fragment key={index}>
          &lt;{node.name}&gt;{children}&lt;/{node.name}&gt;
        </React.Fragment>
      );
    }
    case 'variable':
      return (
        <React.Fragment key={index}>
          {variables?.[(node as Variable).name] ?? `{{ ${(node as Variable).name} }}`}
        </React.Fragment>
      );
  }
}

function serializeAttributes(
  attributes: Attributes,
  variables: { [key: string]: React.ReactNode },
): { [key: string]: string } {
  return Object.keys(attributes).reduce((acc, cur) => {
    let dest = cur;
    if (cur === 'class') {
      dest = 'className'; // replace class with className
    }

    acc[dest] = attributes[cur].reduce((acc2, cur2) => {
      switch (cur2.type) {
        case 'text':
          return acc2 + cur2.text;
        case 'variable': {
          const variable = variables?.[cur2.name];
          if (variable && typeof variable === 'string') {
            return acc2 + variable;
          } else {
            return acc2 + `{{ ${cur2.name} }}`;
          }
        }
        default:
          return acc2;
      }
    }, '');

    return acc;
  }, {});
}

interface Location {
  line: number;
  column: number;
  offset: number;
}

function locationToString({ line, column }: Location) {
  return `${line}:${column}`;
}
